.landing {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--primary-color);
    position: relative;
    overflow: hidden;
}

.globe {
    --x: 250px;
    --y: -50px;
    position: absolute;
    right: 0;   
    top: 0;
    width: 70%;
    z-index: 1;
    /* pointer-events: none; */
    transform: translate(var(--x), var(--y)) rotate(360deg) ;
    /* -webkit-animation: float 6s ease-in-out infinite;
    animation: float 100s ease-in-out infinite; */
}

/* .globe:hover {
    transform: translate(250px, -50px) scale(2.05);
} */


.landing--container {
    margin: 2rem auto;
    width: 80%;
    padding-bottom: 5rem;
    border-radius: 20px;
    clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 100%, 52% 100%, 36% 88%, 0 88%, 0 29%);
    border: 2px solid rgba(255, 255, 255, 0.06);
    background: linear-gradient(104.15deg, rgba(255, 255, 255, 0.06) 3.5%, rgba(255, 255, 255, 0) 89.64%);
}

.landing--content {
    width: 50%;
    padding: 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.landing--content > h1 {
    font-family: Space Mono;
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    letter-spacing: 2px;
    color: var(--text);
    line-height: 104%;
}

.landing--content > p  {
    font-family: Inter;
    width: 80%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 104%;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.8);
    margin: 02rem 0;
}


.landing--content > h3  {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    color: var(--secondary-color);
}


.landing--content > button {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding: 0.25rem 1.85rem;
    line-height: 36px;
    color: var(--text);
    text-transform: inherit;
    margin-top: 2.25rem;
    background: linear-gradient(0deg, #00A5FF, #00A5FF), #FFA83A;
    border-radius: 8px;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 0 66%);
    
}


.landing--countdown {
    margin: 0 10%;
    margin-top: -3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2.5rem;

}

.countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
}

.countdown > h2 {
    font-family: Blender Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    text-transform: uppercase; 
    color: #F8EAFF;
}

.countdown > h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.07em;
    text-transform: uppercase; 
    color: var(--text);   
    margin-top: 1.5rem;
}


.fadeIn {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
 
 @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}


@keyframes float {
	0% {
        transform: translate(var(--x), var(--y)) rotate(360deg) ;

	}
	50% {
        transform: translate(calc(var(--x) - 20px), calc(var(--y) + 20px)) rotate(0deg);
	}
	100% {
        transform: translate(var(--x), var(--y)) rotate(360deg) ;
	}
}


.rotating {
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    transition-delay: none;
    -webkit-transition-delay: now;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
  
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    to {
      -webkit-transform: rotateY(360deg);
      -o-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -webkit-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    to {
      -ms-transform: rotateY(360deg);
      -moz-transform: rotateY(360deg);
      -webkit-transform: rotateY(360deg);
      -o-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 10s linear infinite;
    -moz-animation: rotating 10s linear infinite;
    -ms-animation: rotating 10s linear infinite;
    -o-animation: rotating 10s linear infinite;
    animation: rotating 10s linear infinite;
  }

@media (min-width: 992px) and (max-width: 1380px) {
    .globe {
        width: 65%;
    }
    .landing--container {
        margin: 1.5rem auto;
        width: 80%;
        padding-bottom: 5rem;
    }
    .landing--content {
        width: 50%;
        padding: 2.5rem 2.5rem;
    }
    
    .landing--content > h1 {
        font-size: 3rem;
        letter-spacing: 2px;
        color: var(--text);
        line-height: 104%;
    }
    
    .landing--content > p  {
        width: 80%;
        font-size: 18px;
        margin: 02rem 0;
    }
    
    
    .landing--content > h3  {
        font-size: 19px;
    }
}


@media screen and (max-width: 992px) {
    .landing {
        min-height: 100%;
        padding-bottom: 4rem;
    }
}

@media screen and (max-width: 800px) {

    .globe {
        width: 65%;
        --x: 150px;
        --y: 0px;
    }
    .landing--container {
        margin: 1.5rem 0;
        width: 100%;
        padding-bottom: 5rem;
        border-radius: 0px;
        padding-bottom: 2.5rem;
    }
    .landing--content {
        width: 100%;
        padding: 1rem 1rem;
    }
    
    .landing--content > h1 {
        font-size: 1.8rem;
        letter-spacing: 2px;
        color: var(--text);
        line-height: 104%;
    }
    
    .landing--content > p  {
        width: 60%;
        font-size: 14px;
        margin: 1rem 0;
    }
    
    
    .landing--content > h3  {
        font-size: 15px;
    }

    .landing--content > button {
        font-size: 14px;
        padding: 0.25rem 1.25rem;
        margin-top: 1.25rem;
    }


    .landing--countdown {
        margin: 0;
        margin-top: 0;
        gap: 0.5rem;
        width: 100%;
    }
    
    .countdown {
        width: 80px;
    }
    
    .countdown > h2 {
        font-size: 25px;
    }
    
    .countdown > h4 {
        font-size: 12px;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .globe {
        width: 80%;
        --x: 150px;
        --y: 10px;
    }
}

@media screen and (max-width: 400px) {
    .globe {
        width: 75%;
        --x: 150px;
        --y: 30px;
    }
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}