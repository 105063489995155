.footer {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--primary-color);
   height: 100%;
}
.footer-container {
   clip-path: polygon(0 0, 100% 17%, 100% 100%, 0% 100%);
   /* background: rgb(43 42 43 / 80%); */
   background: #05091c;
   width: 100%;
   height: 100%;
}
.logo img {
   width: 200px;
}
.footer-text {
   font-size: x-large;
}
.footer-text span {
   font-size: x-large;
   font-weight: bold;
}
.footer-icons {
   display: flex;
   justify-content: space-around;
}
.footer-icons a {
   color: var(--text);
}
.footer-icons a:hover {
   color: #6222dc;
   /* color: #f35cf4; */
}
.icon-spec {
   height: 40px;
   width: auto;
   margin-right: 10px;
}
.footer-bottom {
   margin-top: 2rem;
   display: flex;
   justify-content: center;
   padding-bottom: 1rem;
   text-align: center;
}
.footer-bottom p {
   font-family: "Blender Pro";
   color: var(--text);
   font-size: larger;
   font-weight: 300;
}
.footer-column {
   display: flex;
   color: var(--text);
   align-items: center;
   justify-content: space-between;
   padding-bottom: 2%;
}

.desktop-view {
   margin-top: 10%;
   margin-left: 6rem;
   margin-right: 4rem;
   border-bottom: 1px solid rgba(255, 255, 255, 0.3);
   font-family: "Blender Pro";
}
.mobile-view {
   display: none;
}
@media (max-width: 1000px) {
   .footer-text {
      font-size: large;
   }
   .icon-spec {
      height: 25px;
   }
   .desktop-view {
      margin-top: 10%;
      margin-left: 2rem;
      margin-right: 2rem;
   }
   .footer-text span {
      font-size: x-large;
   }
   .footer-bottom {
      display: flex;
      font-size: medium;
   }
   .logo img {
      width: 150px;
      padding-right: 1rem;
   }
}
@media (max-width: 587px) {
   .desktop-view {
      margin-top: 10%;
      margin-left: 1rem;
      margin-right: 1rem;
   }
   .icon-spec {
      height: 20px;
   }
   .logo img {
      width: 130px;
   }
   .footer-text span {
      font-size: large;
   }
   .footer-text {
      font-size: small;
   }
   .footer-bottom {
      margin-top: 1rem;
      font-size: small;
   }
}
@media (max-width: 415px) {
   .desktop-view {
      display: none;
   }
   .mobile-view {
      margin-top: 5rem;
      margin-left: 1rem;
      margin-right: 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      font-family: "Blender Pro";
      display: block;
   }
   .icon-spec {
      height: 15px;
   }
   .footer-text {
      font-size: small;
   }
   .footer-icons {
      display: flex;

      justify-content: center;
   }
   .logo img {
      width: 100px;
   }
   .footer-text span {
      font-size: large;
   }
   .footer-bottom {
      margin-top: 1rem;
      font-size: small;
   }
}
