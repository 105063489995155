@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap);
* {
  margin: 0;
  box-sizing: border-box;
  scroll-padding: 110px 0 0 0;
}


html {
  --scrollbarBG: #070E29;
  --thumbBG: #ffb85f;
}
body::-webkit-scrollbar {
  width: 14px;
}
body {
  scrollbar-color: #ffb85f #070E29;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: #070E29;
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: #ffb85f;
  background-color: var(--thumbBG) ;
  border-radius: 999px;
  border: 3px solid tr;
}
.landing {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--primary-color);
    position: relative;
    overflow: hidden;
}

.globe {
    --x: 250px;
    --y: -50px;
    position: absolute;
    right: 0;   
    top: 0;
    width: 70%;
    z-index: 1;
    /* pointer-events: none; */
    -webkit-transform: translate(var(--x), var(--y)) rotate(360deg) ;
            transform: translate(var(--x), var(--y)) rotate(360deg) ;
    /* -webkit-animation: float 6s ease-in-out infinite;
    animation: float 100s ease-in-out infinite; */
}

/* .globe:hover {
    transform: translate(250px, -50px) scale(2.05);
} */


.landing--container {
    margin: 2rem auto;
    width: 80%;
    padding-bottom: 5rem;
    border-radius: 20px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 100%, 52% 100%, 36% 88%, 0 88%, 0 29%);
            clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 100%, 52% 100%, 36% 88%, 0 88%, 0 29%);
    border: 2px solid rgba(255, 255, 255, 0.06);
    background: linear-gradient(104.15deg, rgba(255, 255, 255, 0.06) 3.5%, rgba(255, 255, 255, 0) 89.64%);
}

.landing--content {
    width: 50%;
    padding: 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.landing--content > h1 {
    font-family: Space Mono;
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    letter-spacing: 2px;
    color: var(--text);
    line-height: 104%;
}

.landing--content > p  {
    font-family: Inter;
    width: 80%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 104%;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.8);
    margin: 02rem 0;
}


.landing--content > h3  {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    color: var(--secondary-color);
}


.landing--content > button {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding: 0.25rem 1.85rem;
    line-height: 36px;
    color: var(--text);
    text-transform: inherit;
    margin-top: 2.25rem;
    background: linear-gradient(0deg, #00A5FF, #00A5FF), #FFA83A;
    border-radius: 8px;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 0 66%);
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 0 66%);
    
}


.landing--countdown {
    margin: 0 10%;
    margin-top: -3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 2.5rem;
    gap: 2.5rem;

}

.countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
}

.countdown > h2 {
    font-family: Blender Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    text-transform: uppercase; 
    color: #F8EAFF;
}

.countdown > h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.07em;
    text-transform: uppercase; 
    color: var(--text);   
    margin-top: 1.5rem;
}


.fadeIn {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
 
 @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}


@-webkit-keyframes float {
	0% {
        -webkit-transform: translate(var(--x), var(--y)) rotate(360deg) ;
                transform: translate(var(--x), var(--y)) rotate(360deg) ;

	}
	50% {
        -webkit-transform: translate(calc(var(--x) - 20px), calc(var(--y) + 20px)) rotate(0deg);
                transform: translate(calc(var(--x) - 20px), calc(var(--y) + 20px)) rotate(0deg);
	}
	100% {
        -webkit-transform: translate(var(--x), var(--y)) rotate(360deg) ;
                transform: translate(var(--x), var(--y)) rotate(360deg) ;
	}
}


@keyframes float {
	0% {
        -webkit-transform: translate(var(--x), var(--y)) rotate(360deg) ;
                transform: translate(var(--x), var(--y)) rotate(360deg) ;

	}
	50% {
        -webkit-transform: translate(calc(var(--x) - 20px), calc(var(--y) + 20px)) rotate(0deg);
                transform: translate(calc(var(--x) - 20px), calc(var(--y) + 20px)) rotate(0deg);
	}
	100% {
        -webkit-transform: translate(var(--x), var(--y)) rotate(360deg) ;
                transform: translate(var(--x), var(--y)) rotate(360deg) ;
	}
}


.rotating {
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    transition-delay: none;
    -webkit-transition-delay: now;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
  
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    to {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }
  @keyframes rotating {
    from {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    to {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 10s linear infinite;
    animation: rotating 10s linear infinite;
  }

@media (min-width: 992px) and (max-width: 1380px) {
    .globe {
        width: 65%;
    }
    .landing--container {
        margin: 1.5rem auto;
        width: 80%;
        padding-bottom: 5rem;
    }
    .landing--content {
        width: 50%;
        padding: 2.5rem 2.5rem;
    }
    
    .landing--content > h1 {
        font-size: 3rem;
        letter-spacing: 2px;
        color: var(--text);
        line-height: 104%;
    }
    
    .landing--content > p  {
        width: 80%;
        font-size: 18px;
        margin: 02rem 0;
    }
    
    
    .landing--content > h3  {
        font-size: 19px;
    }
}


@media screen and (max-width: 992px) {
    .landing {
        min-height: 100%;
        padding-bottom: 4rem;
    }
}

@media screen and (max-width: 800px) {

    .globe {
        width: 65%;
        --x: 150px;
        --y: 0px;
    }
    .landing--container {
        margin: 1.5rem 0;
        width: 100%;
        padding-bottom: 5rem;
        border-radius: 0px;
        padding-bottom: 2.5rem;
    }
    .landing--content {
        width: 100%;
        padding: 1rem 1rem;
    }
    
    .landing--content > h1 {
        font-size: 1.8rem;
        letter-spacing: 2px;
        color: var(--text);
        line-height: 104%;
    }
    
    .landing--content > p  {
        width: 60%;
        font-size: 14px;
        margin: 1rem 0;
    }
    
    
    .landing--content > h3  {
        font-size: 15px;
    }

    .landing--content > button {
        font-size: 14px;
        padding: 0.25rem 1.25rem;
        margin-top: 1.25rem;
    }


    .landing--countdown {
        margin: 0;
        margin-top: 0;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        width: 100%;
    }
    
    .countdown {
        width: 80px;
    }
    
    .countdown > h2 {
        font-size: 25px;
    }
    
    .countdown > h4 {
        font-size: 12px;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .globe {
        width: 80%;
        --x: 150px;
        --y: 10px;
    }
}

@media screen and (max-width: 400px) {
    .globe {
        width: 75%;
        --x: 150px;
        --y: 30px;
    }
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.faq {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--primary-color);
   height: 100%;
   padding-top: 4em;
}

.faqContainer {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   width: 90%;
   margin: 0 2rem;
}

.faqHeader {
   margin-bottom: 3rem;
}

.faqHeader > h1 {
   font-family: Space Mono;
   font-style: normal;
   font-weight: bold;
   text-transform: capitalize;
   font-size: 35px;
   color: var(--secondary-color);
}

.line {
   margin-top: 10px;
   width: 100%;
   height: 2px;
   position: relative;
   background: #956cee;
}
.line::after {
   content: "";
   position: absolute;
   height: 0;
   width: 18%;
   border-top: 10px solid #956cee;
   border-left: 0px solid transparent;
   border-right: 8px solid transparent;
}

.faqs {
   margin: auto;
   width: 75%;
}

.faqsList > .MuiPaper-root {
   background-color: transparent;
}
.faqsList > .MuiPaper-elevation1 {
   box-shadow: none;
}

.faqsList > .MuiAccordion-root {
   margin: 1rem 0;
}

.label1 {
   position: relative !important;
}
.label1::before {
   content: "" !important;
   position: absolute !important;
   top: 0 !important;
   left: 0 !important;
   border-top: 30px solid var(--primary-color) !important;
   border-right: 30px solid rgba(172, 136, 251, 0.18) !important;
}

.MuiAccordionSummary-root {
   background: rgba(172, 136, 251, 0.18) !important;
}

.MuiAccordionSummary-root.Mui-expanded {
   min-height: 64px;
   background: transparent !important;
}

.acc--icon {
   color: var(--secondary-color);
   font-size: 20px;
   border-radius: 100%;
   width: 30px;
   height: 30px;
}

.bg-acc {
   background: #25234f;
}

.faq--header {
   font-family: "Blender Pro";
   font-style: normal;
   font-weight: 500;
   font-size: 22px;
   color: var(--text);
   margin-left: 0.85rem;
}

@media (max-width: 500px) {
   .faqs {
      width: 100%;
   }
}
.singleFAQ > p {
   font-family: "Blender Pro";
   font-style: normal;
   font-weight: normal;
   font-size: 20px;
   letter-spacing: 1px;
   color: rgba(255, 255, 255, 0.9);
   margin: 2rem;
}

@font-face {
   font-family: "Tarrget";
   src: url(/static/media/tarrget.2b6beba0.ttf);
   src: url(/static/media/tarrget.2b6beba0.ttf) format("embedded-opentype");
   src: url(/static/media/tarrget.2b6beba0.ttf) format("truetype");
}

@font-face {
   font-family: "Blender Pro";
   src: url(/static/media/BlenderPro-Medium.29e7e8fd.eot);
   src: local("Blender Pro Medium"), local("BlenderPro-Medium"), url(/static/media/BlenderPro-Medium.29e7e8fd.eot?#iefix) format("embedded-opentype"), url(/static/media/BlenderPro-Medium.2e841829.woff2) format("woff2"),
      url(/static/media/BlenderPro-Medium.e6de85f6.woff) format("woff"), url(/static/media/BlenderPro-Medium.aa0028d9.ttf) format("truetype");
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: "Blender Pro";
   src: url(/static/media/BlenderPro-ThinItalic.5127f51e.eot);
   src: local("Blender Pro Thin Italic"), local("BlenderPro-ThinItalic"), url(/static/media/BlenderPro-ThinItalic.5127f51e.eot?#iefix) format("embedded-opentype"), url(/static/media/BlenderPro-ThinItalic.46265464.woff2) format("woff2"),
      url(/static/media/BlenderPro-ThinItalic.27817076.woff) format("woff"), url(/static/media/BlenderPro-ThinItalic.f4daa3ae.ttf) format("truetype");
   font-weight: 100;
   font-style: italic;
}

@font-face {
   font-family: "Blender Pro";
   src: url(/static/media/BlenderPro-Heavy.98bd9664.eot);
   src: local("Blender Pro Heavy"), local("BlenderPro-Heavy"), url(/static/media/BlenderPro-Heavy.98bd9664.eot?#iefix) format("embedded-opentype"), url(/static/media/BlenderPro-Heavy.644c3e64.woff2) format("woff2"),
      url(/static/media/BlenderPro-Heavy.3f059bd5.woff) format("woff"), url(/static/media/BlenderPro-Heavy.f88b6f77.ttf) format("truetype");
   font-weight: 900;
   font-style: normal;
}

@font-face {
   font-family: "Blender Pro";
   src: url(/static/media/BlenderPro-Bold.0d5fe602.eot);
   src: local("Blender Pro Bold"), local("BlenderPro-Bold"), url(/static/media/BlenderPro-Bold.0d5fe602.eot?#iefix) format("embedded-opentype"), url(/static/media/BlenderPro-Bold.18483170.woff2) format("woff2"),
      url(/static/media/BlenderPro-Bold.fc9db575.woff) format("woff"), url(/static/media/BlenderPro-Bold.7914e5f0.ttf) format("truetype");
   font-weight: bold;
   font-style: normal;
}

@font-face {
   font-family: "Blender Pro";
   src: url(/static/media/BlenderPro-Thin.fe1a8189.eot);
   src: local("Blender Pro Thin"), local("BlenderPro-Thin"), url(/static/media/BlenderPro-Thin.fe1a8189.eot?#iefix) format("embedded-opentype"), url(/static/media/BlenderPro-Thin.53165eef.woff2) format("woff2"),
      url(/static/media/BlenderPro-Thin.16c88287.woff) format("woff"), url(/static/media/BlenderPro-Thin.c7ed6071.ttf) format("truetype");
   font-weight: 100;
   font-style: normal;
}

@font-face {
   font-family: "Blender Pro";
   src: url(/static/media/BlenderPro-MediumItalic.c4f70654.eot);
   src: local("Blender Pro Medium Italic"), local("BlenderPro-MediumItalic"), url(/static/media/BlenderPro-MediumItalic.c4f70654.eot?#iefix) format("embedded-opentype"), url(/static/media/BlenderPro-MediumItalic.e16eb73e.woff2) format("woff2"),
      url(/static/media/BlenderPro-MediumItalic.e2029f41.woff) format("woff"), url(/static/media/BlenderPro-MediumItalic.5b40fe62.ttf) format("truetype");
   font-weight: 500;
   font-style: italic;
}

@font-face {
   font-family: "Blender Pro";
   src: url(/static/media/BlenderPro-BoldItalic.dbfd61d5.eot);
   src: local("Blender Pro Bold Italic"), local("BlenderPro-BoldItalic"), url(/static/media/BlenderPro-BoldItalic.dbfd61d5.eot?#iefix) format("embedded-opentype"), url(/static/media/BlenderPro-BoldItalic.fa206370.woff2) format("woff2"),
      url(/static/media/BlenderPro-BoldItalic.ea31b15d.woff) format("woff"), url(/static/media/BlenderPro-BoldItalic.19211481.ttf) format("truetype");
   font-weight: bold;
   font-style: italic;
}

.speakers {
  background-color: var(--primary-color);
  color: #fff;
}
.speakers-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.speakers-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
}

.speakers-wrapper {
  margin: auto;
  width: 90%;
}

.speakers-container-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.speakers-card {
  width: 220px;
  margin: 35px 25px;
}

.speakers-content {
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.speakers-content:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.speakers-card-img {
  height: 200px;
  width: 100%;
}

.speakers-card-body {
  background-color: #956cee;
  height: 75px;
  text-align: center;
  margin-top: -5px;
}

.speakers-title {
  padding-top: 10px;
  font-family: "Blender Pro";
  font-style: normal;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 28px;
}

.speakers-company {
  font-family: "Blender Pro";
  font-style: normal;
  font-weight: normal;
}
.speakers-img-1 {
  position: absolute;
  display: flex;
  margin-right: auto;
  height: 200px;
  margin-left: -2rem;
}
.coming-soon {
  font-family: "Blender Pro";
  font-size: 30px;
  position: absolute;
  margin-top: 5rem;
}

.about-img {
  height: 334px;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: 0;
  margin-top: -5rem;
  pointer-events: none;
}

@media only screen and (max-width: 1200px) {
  .about-img {
    height: 200px;
  }
  .coming-soon {
    margin-top: 0rem;
  }
}
@media only screen and (max-width: 756px) {
  .about-img {
    height: 150px;
    margin-top: -3rem;
  }
}

@media only screen and (max-width: 415px) {
  .faqHeader h1 {
    font-size: 25px;
  }
  .speakers-container {
    width: 85%;
  }
  .coming-soon {
    font-size: 25px;
  }
  .about-img {
    height: 120px;
    margin-top: -2rem;
  }
}

.about {
   background-color: var(--primary-color);
   height: 100%;
}
.about-main {
   display: flex;
   align-items: center;
   justify-content: center;
}

.aboutcontainer {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   width: 90%;
   margin: 0 2rem;
}

.aboutheader {
   margin-bottom: 2rem;
   margin-top: 2rem;
}

.aboutheader h1 {
   font-family: Space Mono;
   font-style: normal;
   font-weight: bold;
   font-size: 35px;
   color: var(--secondary-color);
   text-transform: capitalize;
}

.line {
   margin-top: 10px;
   width: 100%;
   height: 2px;
   position: relative;
   background: #956cee;
}
.line::after {
   content: "";
   position: absolute;
   height: 0;
   width: 18%;
   border-top: 10px solid #956cee;
   border-left: 0px solid transparent;
   border-right: 8px solid transparent;
}

.aboutContent {
   position: relative;
   margin: auto;
   margin-top: -4rem;
   width: 70%;
}
.aboutContent p {
   color: var(--text);
   text-align: center;
   font-family: "Blender Pro";
   font-style: normal;
   font-size: 24px;
   letter-spacing: 1.5px;
}
.btn-container {
   margin-top: 10px;
   display: grid;
}
.signupbtn {
   margin: auto;
   margin-top: 3rem;
   background: #061d3e;
   border: 2px solid #035994;
   color: #6eccff;
   height: 50px;
   width: 200px;
   font-size: 18px;
   letter-spacing: 1px;
   cursor: pointer;
}
.ethereum2 {
   position: relative;
   margin-right: 0rem;
   margin-left: auto;
   height: 100px;
   pointer-events: none;
}
.Ethereum1 {
   height: 100px;
   position: relative;
   margin-top: -5rem;
   pointer-events: none;
}
/* .signupbtn {
  font-family: Inter;
  margin: auto;
  background: none;
  background: rgba(0, 165, 255, 0.1);
  border: 2.5px solid #00a5ff;
  box-sizing: border-box;
  color: #6fccff;
  height: 50px;
  width: 240px;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 1rem;
  overflow: hidden;
} */
.btn .btn::after {
   transition: all 0.3s;
}

.btn {
   background: none;
   font-family: Inter;
   margin: auto;
   border: 2.5px solid #00a5ff;
   border-radius: 5px;
   color: #fff;
   display: block;
   box-sizing: border-box;

   font-weight: bold;
   /* height: 50px;
  width: 240px;
  font-size: 18px; */
   font-size: 1.2rem;
   height: 60px;
   width: 260px;

   letter-spacing: 1px;
   margin-top: 1rem;
   overflow: hidden;
   position: relative;
}

.btn::before,
.btn::after {
   background: rgba(0, 166, 255, 0.205);
   content: "";
   position: absolute;
   z-index: -1;
}

.btn::after {
   height: 100%;
   left: -35%;
   top: 0;
   -webkit-transform: skew(50deg);
           transform: skew(50deg);
   transition-duration: 0.6s;
   -webkit-transform-origin: top left;
           transform-origin: top left;
   width: 0;
}

.btn:hover:after {
   height: 100%;
   width: 135%;
}

@media only screen and (max-width: 756px) {
   .aboutContent {
      width: 85%;
      margin-top: 0rem;
   }
   .Ethereum1 {
      margin-top: 0;
   }
}
@media only screen and (max-width: 415px) {
   .ethereum2 {
      height: 58px;
      margin-top: -2rem;
   }
   .Ethereum1 {
      height: 54px;
   }

   .aboutContent {
      width: 95%;
   }
   .aboutContent p {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.5px;
   }
   .aboutheader h1 {
      font-size: 25px;
   }
   .btn {
      width: 174px;
      height: 34.8px;
      font-size: 15px;
      border: 2px solid #00a5ff;
   }
}

.navbar {
   background: var(--primary-color);
   height: 110px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: sticky;
   top: 0;
   z-index: 999;
}

.nav-container {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   margin: 0 6rem;
   padding-left: 2%;
}

.nav-left > img {
   width: 80%;
   width: 80%;
}
.nav-left a {
   width: 100%;
}

.nav-center {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   text-decoration: none;
}

.nav-item {
   text-decoration: none;
   font-family: "Inter", sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 18px;
   line-height: 24px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   margin: 0 1rem;
   color: var(--text);
}

.nav-right {
   display: flex;
   align-items: center;
   justify-content: center;
}

.nav-right > img {
   width: 50%;
}

.navRight-content {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.navRight-content > h3 {
   font-family: "Montserrat", sans-serif;
   font-style: normal;
   font-weight: 300;
   font-size: 20px;
   line-height: 24px;
   text-transform: uppercase;
   color: var(--text);
}

.navRight-content > h5 {
   font-family: "Montserrat", sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   -webkit-font-feature-settings: "smcp";
           font-feature-settings: "smcp";
   font-variant: small-caps;
   color: var(--text);
}

.nav__drawer {
   display: none;
}

.nav_open_icon {
   color: var(--text);
   font-size: 2.35rem;
   cursor: pointer;
}

.drawer__container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   margin-top: 4rem;
   /* gap: 2.5rem; */
}

.drawer__link {
   text-decoration: none;
   font-family: "Inter", sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 18px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   color: var(--text);
   margin-bottom: 2.5rem;
}

/* .drawer > .MuiBackdrop-root {
  background: rgba(33, 33, 33, 0.15) !important;
  backdrop-filter: blur(20px) !important;
} */

@media (min-width: 992px) and (max-width: 1380px) {
   .nav-container {
      margin: 0 2.5rem;
   }
}

@media screen and (max-width: 992px) {
   .nav-center,
   .nav-right {
      display: none;
   }
   .nav__drawer {
      display: block;
   }

   .nav-container {
      margin: 0 1rem;
   }
   .nav-left > img {
      width: 45%;
   }
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 400px) {
   .drawer__link {
      margin-bottom: 2rem;
   }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}

.footer {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--primary-color);
   height: 100%;
}
.footer-container {
   -webkit-clip-path: polygon(0 0, 100% 17%, 100% 100%, 0% 100%);
           clip-path: polygon(0 0, 100% 17%, 100% 100%, 0% 100%);
   /* background: rgb(43 42 43 / 80%); */
   background: #05091c;
   width: 100%;
   height: 100%;
}
.logo img {
   width: 200px;
}
.footer-text {
   font-size: x-large;
}
.footer-text span {
   font-size: x-large;
   font-weight: bold;
}
.footer-icons {
   display: flex;
   justify-content: space-around;
}
.footer-icons a {
   color: var(--text);
}
.footer-icons a:hover {
   color: #6222dc;
   /* color: #f35cf4; */
}
.icon-spec {
   height: 40px;
   width: auto;
   margin-right: 10px;
}
.footer-bottom {
   margin-top: 2rem;
   display: flex;
   justify-content: center;
   padding-bottom: 1rem;
   text-align: center;
}
.footer-bottom p {
   font-family: "Blender Pro";
   color: var(--text);
   font-size: larger;
   font-weight: 300;
}
.footer-column {
   display: flex;
   color: var(--text);
   align-items: center;
   justify-content: space-between;
   padding-bottom: 2%;
}

.desktop-view {
   margin-top: 10%;
   margin-left: 6rem;
   margin-right: 4rem;
   border-bottom: 1px solid rgba(255, 255, 255, 0.3);
   font-family: "Blender Pro";
}
.mobile-view {
   display: none;
}
@media (max-width: 1000px) {
   .footer-text {
      font-size: large;
   }
   .icon-spec {
      height: 25px;
   }
   .desktop-view {
      margin-top: 10%;
      margin-left: 2rem;
      margin-right: 2rem;
   }
   .footer-text span {
      font-size: x-large;
   }
   .footer-bottom {
      display: flex;
      font-size: medium;
   }
   .logo img {
      width: 150px;
      padding-right: 1rem;
   }
}
@media (max-width: 587px) {
   .desktop-view {
      margin-top: 10%;
      margin-left: 1rem;
      margin-right: 1rem;
   }
   .icon-spec {
      height: 20px;
   }
   .logo img {
      width: 130px;
   }
   .footer-text span {
      font-size: large;
   }
   .footer-text {
      font-size: small;
   }
   .footer-bottom {
      margin-top: 1rem;
      font-size: small;
   }
}
@media (max-width: 415px) {
   .desktop-view {
      display: none;
   }
   .mobile-view {
      margin-top: 5rem;
      margin-left: 1rem;
      margin-right: 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      font-family: "Blender Pro";
      display: block;
   }
   .icon-spec {
      height: 15px;
   }
   .footer-text {
      font-size: small;
   }
   .footer-icons {
      display: flex;

      justify-content: center;
   }
   .logo img {
      width: 100px;
   }
   .footer-text span {
      font-size: large;
   }
   .footer-bottom {
      margin-top: 1rem;
      font-size: small;
   }
}

.backToTop {
    position: fixed; 
    right: 10px;
    bottom: 60px;
    height: 30px;
    font-size: 3rem;
    z-index: 999;
}

.backToTop button {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .backToTop { 
        right: -10px;
        bottom: 50px;
        font-size: 2.75rem;
    }
}
.app {

}

:root {
    --primary-color: #070E29;
    --secondary-color: #ffb85f;
    --text: #ffffff;
}
