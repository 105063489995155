.speakers {
  background-color: var(--primary-color);
  color: #fff;
}
.speakers-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.speakers-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
}

.speakers-wrapper {
  margin: auto;
  width: 90%;
}

.speakers-container-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.speakers-card {
  width: 220px;
  margin: 35px 25px;
}

.speakers-content {
  transition: transform 0.5s ease-in-out;
}

.speakers-content:hover {
  transform: scale(1.05);
}

.speakers-card-img {
  height: 200px;
  width: 100%;
}

.speakers-card-body {
  background-color: #956cee;
  height: 75px;
  text-align: center;
  margin-top: -5px;
}

.speakers-title {
  padding-top: 10px;
  font-family: "Blender Pro";
  font-style: normal;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 28px;
}

.speakers-company {
  font-family: "Blender Pro";
  font-style: normal;
  font-weight: normal;
}
.speakers-img-1 {
  position: absolute;
  display: flex;
  margin-right: auto;
  height: 200px;
  margin-left: -2rem;
}
.coming-soon {
  font-family: "Blender Pro";
  font-size: 30px;
  position: absolute;
  margin-top: 5rem;
}

.about-img {
  height: 334px;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: 0;
  margin-top: -5rem;
  pointer-events: none;
}

@media only screen and (max-width: 1200px) {
  .about-img {
    height: 200px;
  }
  .coming-soon {
    margin-top: 0rem;
  }
}
@media only screen and (max-width: 756px) {
  .about-img {
    height: 150px;
    margin-top: -3rem;
  }
}

@media only screen and (max-width: 415px) {
  .faqHeader h1 {
    font-size: 25px;
  }
  .speakers-container {
    width: 85%;
  }
  .coming-soon {
    font-size: 25px;
  }
  .about-img {
    height: 120px;
    margin-top: -2rem;
  }
}
