.faq {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--primary-color);
   height: 100%;
   padding-top: 4em;
}

.faqContainer {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   width: 90%;
   margin: 0 2rem;
}

.faqHeader {
   margin-bottom: 3rem;
}

.faqHeader > h1 {
   font-family: Space Mono;
   font-style: normal;
   font-weight: bold;
   text-transform: capitalize;
   font-size: 35px;
   color: var(--secondary-color);
}

.line {
   margin-top: 10px;
   width: 100%;
   height: 2px;
   position: relative;
   background: #956cee;
}
.line::after {
   content: "";
   position: absolute;
   height: 0;
   width: 18%;
   border-top: 10px solid #956cee;
   border-left: 0px solid transparent;
   border-right: 8px solid transparent;
}

.faqs {
   margin: auto;
   width: 75%;
}

.faqsList > .MuiPaper-root {
   background-color: transparent;
}
.faqsList > .MuiPaper-elevation1 {
   box-shadow: none;
}

.faqsList > .MuiAccordion-root {
   margin: 1rem 0;
}

.label1 {
   position: relative !important;
}
.label1::before {
   content: "" !important;
   position: absolute !important;
   top: 0 !important;
   left: 0 !important;
   border-top: 30px solid var(--primary-color) !important;
   border-right: 30px solid rgba(172, 136, 251, 0.18) !important;
}

.MuiAccordionSummary-root {
   background: rgba(172, 136, 251, 0.18) !important;
}

.MuiAccordionSummary-root.Mui-expanded {
   min-height: 64px;
   background: transparent !important;
}

.acc--icon {
   color: var(--secondary-color);
   font-size: 20px;
   border-radius: 100%;
   width: 30px;
   height: 30px;
}

.bg-acc {
   background: #25234f;
}

.faq--header {
   font-family: "Blender Pro";
   font-style: normal;
   font-weight: 500;
   font-size: 22px;
   color: var(--text);
   margin-left: 0.85rem;
}

@media (max-width: 500px) {
   .faqs {
      width: 100%;
   }
}
.singleFAQ > p {
   font-family: "Blender Pro";
   font-style: normal;
   font-weight: normal;
   font-size: 20px;
   letter-spacing: 1px;
   color: rgba(255, 255, 255, 0.9);
   margin: 2rem;
}

@font-face {
   font-family: "Tarrget";
   src: url("../../assets/fonts/tarrget/tarrget.ttf");
   src: url("../../assets/fonts/tarrget/tarrget.ttf") format("embedded-opentype");
   src: url("../../assets/fonts/tarrget/tarrget.ttf") format("truetype");
}

@font-face {
   font-family: "Blender Pro";
   src: url("../../assets/fonts/blenderpro/BlenderPro-Medium.eot");
   src: local("Blender Pro Medium"), local("BlenderPro-Medium"), url("../../assets/fonts/blenderpro/BlenderPro-Medium.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/blenderpro/BlenderPro-Medium.woff2") format("woff2"),
      url("../../assets/fonts/blenderpro/BlenderPro-Medium.woff") format("woff"), url("../../assets/fonts/blenderpro/BlenderPro-Medium.ttf") format("truetype");
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: "Blender Pro";
   src: url("../../assets/fonts/blenderpro/BlenderPro-ThinItalic.eot");
   src: local("Blender Pro Thin Italic"), local("BlenderPro-ThinItalic"), url("../../assets/fonts/blenderpro/BlenderPro-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/blenderpro/BlenderPro-ThinItalic.woff2") format("woff2"),
      url("../../assets/fonts/blenderpro/BlenderPro-ThinItalic.woff") format("woff"), url("../../assets/fonts/blenderpro/BlenderPro-ThinItalic.ttf") format("truetype");
   font-weight: 100;
   font-style: italic;
}

@font-face {
   font-family: "Blender Pro";
   src: url("../../assets/fonts/blenderpro/BlenderPro-Heavy.eot");
   src: local("Blender Pro Heavy"), local("BlenderPro-Heavy"), url("../../assets/fonts/blenderpro/BlenderPro-Heavy.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/blenderpro/BlenderPro-Heavy.woff2") format("woff2"),
      url("../../assets/fonts/blenderpro/BlenderPro-Heavy.woff") format("woff"), url("../../assets/fonts/blenderpro/BlenderPro-Heavy.ttf") format("truetype");
   font-weight: 900;
   font-style: normal;
}

@font-face {
   font-family: "Blender Pro";
   src: url("../../assets/fonts/blenderpro/BlenderPro-Bold.eot");
   src: local("Blender Pro Bold"), local("BlenderPro-Bold"), url("../../assets/fonts/blenderpro/BlenderPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/blenderpro/BlenderPro-Bold.woff2") format("woff2"),
      url("../../assets/fonts/blenderpro/BlenderPro-Bold.woff") format("woff"), url("../../assets/fonts/blenderpro/BlenderPro-Bold.ttf") format("truetype");
   font-weight: bold;
   font-style: normal;
}

@font-face {
   font-family: "Blender Pro";
   src: url("../../assets/fonts/blenderpro/BlenderPro-Thin.eot");
   src: local("Blender Pro Thin"), local("BlenderPro-Thin"), url("../../assets/fonts/blenderpro/BlenderPro-Thin.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/blenderpro/BlenderPro-Thin.woff2") format("woff2"),
      url("../../assets/fonts/blenderpro/BlenderPro-Thin.woff") format("woff"), url("../../assets/fonts/blenderpro/BlenderPro-Thin.ttf") format("truetype");
   font-weight: 100;
   font-style: normal;
}

@font-face {
   font-family: "Blender Pro";
   src: url("../../assets/fonts/blenderpro/BlenderPro-MediumItalic.eot");
   src: local("Blender Pro Medium Italic"), local("BlenderPro-MediumItalic"), url("../../assets/fonts/blenderpro/BlenderPro-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/blenderpro/BlenderPro-MediumItalic.woff2") format("woff2"),
      url("../../assets/fonts/blenderpro/BlenderPro-MediumItalic.woff") format("woff"), url("../../assets/fonts/blenderpro/BlenderPro-MediumItalic.ttf") format("truetype");
   font-weight: 500;
   font-style: italic;
}

@font-face {
   font-family: "Blender Pro";
   src: url("../../assets/fonts/blenderpro/BlenderPro-BoldItalic.eot");
   src: local("Blender Pro Bold Italic"), local("BlenderPro-BoldItalic"), url("../../assets/fonts/blenderpro/BlenderPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/blenderpro/BlenderPro-BoldItalic.woff2") format("woff2"),
      url("../../assets/fonts/blenderpro/BlenderPro-BoldItalic.woff") format("woff"), url("../../assets/fonts/blenderpro/BlenderPro-BoldItalic.ttf") format("truetype");
   font-weight: bold;
   font-style: italic;
}
