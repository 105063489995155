.about {
   background-color: var(--primary-color);
   height: 100%;
}
.about-main {
   display: flex;
   align-items: center;
   justify-content: center;
}

.aboutcontainer {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   width: 90%;
   margin: 0 2rem;
}

.aboutheader {
   margin-bottom: 2rem;
   margin-top: 2rem;
}

.aboutheader h1 {
   font-family: Space Mono;
   font-style: normal;
   font-weight: bold;
   font-size: 35px;
   color: var(--secondary-color);
   text-transform: capitalize;
}

.line {
   margin-top: 10px;
   width: 100%;
   height: 2px;
   position: relative;
   background: #956cee;
}
.line::after {
   content: "";
   position: absolute;
   height: 0;
   width: 18%;
   border-top: 10px solid #956cee;
   border-left: 0px solid transparent;
   border-right: 8px solid transparent;
}

.aboutContent {
   position: relative;
   margin: auto;
   margin-top: -4rem;
   width: 70%;
}
.aboutContent p {
   color: var(--text);
   text-align: center;
   font-family: "Blender Pro";
   font-style: normal;
   font-size: 24px;
   letter-spacing: 1.5px;
}
.btn-container {
   margin-top: 10px;
   display: grid;
}
.signupbtn {
   margin: auto;
   margin-top: 3rem;
   background: #061d3e;
   border: 2px solid #035994;
   color: #6eccff;
   height: 50px;
   width: 200px;
   font-size: 18px;
   letter-spacing: 1px;
   cursor: pointer;
}
.ethereum2 {
   position: relative;
   margin-right: 0rem;
   margin-left: auto;
   height: 100px;
   pointer-events: none;
}
.Ethereum1 {
   height: 100px;
   position: relative;
   margin-top: -5rem;
   pointer-events: none;
}
/* .signupbtn {
  font-family: Inter;
  margin: auto;
  background: none;
  background: rgba(0, 165, 255, 0.1);
  border: 2.5px solid #00a5ff;
  box-sizing: border-box;
  color: #6fccff;
  height: 50px;
  width: 240px;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 1rem;
  overflow: hidden;
} */
.btn .btn::after {
   -webkit-transition: all 0.3s;
   -moz-transition: all 0.3s;
   -o-transition: all 0.3s;
   transition: all 0.3s;
}

.btn {
   background: none;
   font-family: Inter;
   margin: auto;
   border: 2.5px solid #00a5ff;
   border-radius: 5px;
   color: #fff;
   display: block;
   box-sizing: border-box;

   font-weight: bold;
   /* height: 50px;
  width: 240px;
  font-size: 18px; */
   font-size: 1.2rem;
   height: 60px;
   width: 260px;

   letter-spacing: 1px;
   margin-top: 1rem;
   overflow: hidden;
   position: relative;
}

.btn::before,
.btn::after {
   background: rgba(0, 166, 255, 0.205);
   content: "";
   position: absolute;
   z-index: -1;
}

.btn::after {
   height: 100%;
   left: -35%;
   top: 0;
   transform: skew(50deg);
   transition-duration: 0.6s;
   transform-origin: top left;
   width: 0;
}

.btn:hover:after {
   height: 100%;
   width: 135%;
}

@media only screen and (max-width: 756px) {
   .aboutContent {
      width: 85%;
      margin-top: 0rem;
   }
   .Ethereum1 {
      margin-top: 0;
   }
}
@media only screen and (max-width: 415px) {
   .ethereum2 {
      height: 58px;
      margin-top: -2rem;
   }
   .Ethereum1 {
      height: 54px;
   }

   .aboutContent {
      width: 95%;
   }
   .aboutContent p {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.5px;
   }
   .aboutheader h1 {
      font-size: 25px;
   }
   .btn {
      width: 174px;
      height: 34.8px;
      font-size: 15px;
      border: 2px solid #00a5ff;
   }
}
