.navbar {
   background: var(--primary-color);
   height: 110px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: sticky;
   top: 0;
   z-index: 999;
}

.nav-container {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   margin: 0 6rem;
   padding-left: 2%;
}

.nav-left > img {
   width: 80%;
   width: 80%;
}
.nav-left a {
   width: 100%;
}

.nav-center {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   text-decoration: none;
}

.nav-item {
   text-decoration: none;
   font-family: "Inter", sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 18px;
   line-height: 24px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   margin: 0 1rem;
   color: var(--text);
}

.nav-right {
   display: flex;
   align-items: center;
   justify-content: center;
}

.nav-right > img {
   width: 50%;
}

.navRight-content {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.navRight-content > h3 {
   font-family: "Montserrat", sans-serif;
   font-style: normal;
   font-weight: 300;
   font-size: 20px;
   line-height: 24px;
   text-transform: uppercase;
   color: var(--text);
}

.navRight-content > h5 {
   font-family: "Montserrat", sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   font-variant: small-caps;
   color: var(--text);
}

.nav__drawer {
   display: none;
}

.nav_open_icon {
   color: var(--text);
   font-size: 2.35rem;
   cursor: pointer;
}

.drawer__container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   margin-top: 4rem;
   /* gap: 2.5rem; */
}

.drawer__link {
   text-decoration: none;
   font-family: "Inter", sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 18px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   color: var(--text);
   margin-bottom: 2.5rem;
}

/* .drawer > .MuiBackdrop-root {
  background: rgba(33, 33, 33, 0.15) !important;
  backdrop-filter: blur(20px) !important;
} */

@media (min-width: 992px) and (max-width: 1380px) {
   .nav-container {
      margin: 0 2.5rem;
   }
}

@media screen and (max-width: 992px) {
   .nav-center,
   .nav-right {
      display: none;
   }
   .nav__drawer {
      display: block;
   }

   .nav-container {
      margin: 0 1rem;
   }
   .nav-left > img {
      width: 45%;
   }
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 400px) {
   .drawer__link {
      margin-bottom: 2rem;
   }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}
