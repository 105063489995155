@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
  scroll-padding: 110px 0 0 0;
}


html {
  --scrollbarBG: #070E29;
  --thumbBG: #ffb85f;
}
body::-webkit-scrollbar {
  width: 14px;
}
body {
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 999px;
  border: 3px solid tr;
}